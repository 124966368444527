import React from 'react'
import './forms.module.scss'

const Contact = ({ pathname }) => {
    return (
        <section className='container contact-form'>
            <form name='contact' method='POST' data-netlify='true' data-netlify-honeypot='bot-field' data-netlify-recaptcha='true'>
                <p hidden>
                    <label>
                        Don't fill this out: <input name='bot-field' />
                    </label>
                </p>
                <p className='form-field'>
                    <label htmlFor='name'>Name</label>
                    <input type='text' name='name' id='name' />
                </p>
                <p className='form-field'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' name='email' id='email' />
                </p>
                <p className='form-field'>
                    <label htmlFor='comments'>Comments</label>
                    <textarea rows='5' name='comments' id='comments' />
                </p>
                <input type="hidden" name="form-name" value="contact" />
                <div data-netlify-recaptcha='true'></div>
                <p>
                    <button type='submit' className='button cta-button'>Send</button>
                </p>
            </form>
        </section>
    )
}

export default Contact